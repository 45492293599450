import axios, { AxiosError, AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import { ElMessage, ElMessageBox } from 'element-plus'
// import qs from 'qs'
import { config } from '@/config/axios/config'
import { useCache } from '@/hooks/web/useCache'
import { resetRouter } from '@/router'
import { getAccessToken, getTenantId, removeToken } from '@/utils/auth'
import { isEmpty } from 'lodash-es'
import errorCode from './errorCode'

const tenantEnable = import.meta.env.VITE_APP_TENANT_ENABLE
const { successCode, baseUrl, requestTimeout } = config

// 创建axios实例
const service: AxiosInstance = axios.create({
  baseURL: baseUrl, // api 的 base_url
  timeout: requestTimeout, // 请求超时时间
  withCredentials: false // 禁用 Cookie 等信息
})

// request拦截器
service.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    // 是否需要设置 token

    const token = getAccessToken()
    if (!isEmpty(token)) {
      config.headers[import.meta.env.VITE_API_AUTHORIZATION_NAME] = 'Bearer ' + token // 让每个请求携带自定义token
    }
    // 设置租户
    if (tenantEnable && tenantEnable === 'true') {
      const tenantId = getTenantId()
      if (tenantId) config.headers['tenant-id'] = tenantId
    }

    // 设置app-id
    config.headers['app-id'] = import.meta.env.VITE_API_APP_ID

    return config
  },
  (error: AxiosError) => {
    Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(
  (response: AxiosResponse<any>) => {
    const data: IResponse = response.data
    // 二进制数据则直接返回
    if (
      response.request.responseType === 'blob' ||
      response.request.responseType === 'arraybuffer'
    ) {
      return response.data
    }

    //// 非二进制数据
    // 成功代码
    if (data.code === successCode) return data

    if ([401, 406, 407, 408].includes(data.code)) {
      handleAuthorized()
      return Promise.reject(new Error(data.msg))
    } else {
      // 获取错误信息
      const msg = data.msg || errorCode[data.code] || errorCode['default']
      ElMessage.error(msg)
      return Promise.reject(new Error(msg))
    }
  },
  (error: AxiosError) => {
    let { message } = error
    const { t } = useI18n()
    if (message === 'Network Error') {
      message = t('sys.api.errorMessage')
    } else if (message.includes('timeout')) {
      message = t('sys.api.apiTimeoutMessage')
    } else if (message.includes('Request failed with status code')) {
      message = t('sys.api.apiRequestFailed') + message.substr(message.length - 3)
    }
    ElMessage.error(message)
    return Promise.reject(error)
  }
)

/**
 * 处理无权限
 */
const handleAuthorized = () => {
  const { t } = useI18n()

  ElMessageBox.confirm(t('sys.api.timeoutMessage'), t('common.confirmTitle'), {
    confirmButtonText: t('login.relogin'),
    cancelButtonText: t('common.cancel'),
    type: 'warning',
    showClose: false,
    showCancelButton: false,
    closeOnClickModal: false,
    closeOnPressEscape: false
  }).then(() => {
    const { wsCache } = useCache()
    resetRouter() // 重置静态路由表
    wsCache.clear()
    removeToken()

    window.location.href = '/'
  })
}
export { service }
