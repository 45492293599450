import qs from 'qs'
import { config } from './config'
import { service } from './service'

const { defaultHeaders } = config

const request = (option: any) => {
  return service({
    ...option,
    headers: {
      'Content-Type': option.headersType || defaultHeaders
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { indices: false })
    }
  })
}
export default {
  get: <T = any>(option: any) => {
    return new Promise<T>((resolve, reject) => {
      request({ method: 'GET', ...option })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => reject(err))
    })
  },
  post: <T = any>(option: any) => {
    return new Promise<T>((resolve, reject) => {
      request({ method: 'POST', ...option })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => reject(err))
    })
  },
  postOriginal: async (option: any) => {
    const res = await request({ method: 'POST', ...option })
    return res
  },
  delete: <T = any>(option: any) => {
    return new Promise<T>((resolve, reject) => {
      request({ method: 'DELETE', ...option })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => reject(err))
    })
  },
  put: async <T = any>(option: any) => {
    const res = await request({ method: 'PUT', ...option })
    return res.data as unknown as T
  },
  download: async <T = any>(option: any) => {
    const res = await request({ method: 'GET', responseType: 'blob', ...option })
    return res as unknown as Promise<T>
  },
  upload: async <T = any>(option: any) => {
    option.headersType = 'multipart/form-data'
    const res = await request({ method: 'POST', ...option })
    return res as unknown as Promise<T>
  }
}
